import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, FetchListRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { PaymentSearchReadModel, PaymentsSearchCriteria } from '../payments/PaymentEntities';
import $ from "jquery";
import { CoreResponse } from '../../serverresponse/serverresponse';
import { actionCreator as uiActionCreator } from '../../uiproperties/UIState';
import moment from 'moment';

const debouncedActions = (dispatch) => {
    dispatch(actionsCreator.resetList());
    dispatch(sharedActions.load(false));
    dispatch(sharedActions.count());
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface MovementState {
    list: Array<PaymentSearchReadModel>;
    searchCriteria: PaymentsSearchCriteria;
    count?: number;
    itemToDelete?: string;
}

const getBaseState = (): MovementState => {
    return {
        list: [],
        searchCriteria: {}
    } as MovementState;
}

const getDefaultSearchCriteria = (eventId: string): PaymentsSearchCriteria => {
    return {
        eventId: eventId,
        pageNumber: 0,
        pageSize: 10,
        hasNextPage: true
    } as PaymentsSearchCriteria;
}

enum TypeKeys {
    RESET_LIST = "MOVEMENTS_RESET_LIST",
    APPEND_LIST = "MOVEMENTS_APPEND_LIST",
    RESET_STATE = "MOVEMENTS_RESET_STATE",
    SET_SEARCH_CRITERIA = "MOVEMENTS_SET_SEARCH_CRITERIA",
    SET_COUNT = "MOVEMENTS_SET_COUNT",
    SET_ITEM_TO_DELETE = "MOVEMENTS_SET_ITEM_TO_DELETE"
}

interface SetItemToDelete extends Action {
    type: TypeKeys.SET_ITEM_TO_DELETE;
    id: string;
}

interface SetCount extends Action {
    type: TypeKeys.SET_COUNT;
    count: number;
}

interface ResetList extends Action {
    type: TypeKeys.RESET_LIST;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    list: Array<PaymentSearchReadModel>;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: PaymentsSearchCriteria;
}

type MovementsActions =
    AppendList
    | ResetState
    | SetSearchCriteria
    | ResetList
    | SetCount
    | SetItemToDelete;


export const actionsCreator = {
    appendList: (items: Array<PaymentSearchReadModel>): AppendList => ({
        list: items,
        type: TypeKeys.APPEND_LIST
    }),
    setSearchCriteria: (searchCriteria: PaymentsSearchCriteria): SetSearchCriteria => ({
        searchCriteria: searchCriteria,
        type: TypeKeys.SET_SEARCH_CRITERIA
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    resetList: (): ResetList => ({
        type: TypeKeys.RESET_LIST
    }),
    setCount: (count: number): SetCount => ({
        type: TypeKeys.SET_COUNT,
        count: count
    }),
    setItemToDelete: (id: string): SetItemToDelete => ({
        type: TypeKeys.SET_ITEM_TO_DELETE,
        id: id
    })
}


export const sharedActions = {
    load: (preservePages: boolean) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Array<PaymentSearchReadModel>>> => {
        let promise = $.Deferred();


        let requestModel = new FetchListRequest(`/payments/search?preservePages=${preservePages}`, RequestMethods.POST, getState().movements.searchCriteria);

        ajaxRequest<Array<PaymentSearchReadModel>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
                return;
            }

            dispatch(actionsCreator.appendList(response.entity));

            let searchCriteriaToSave = { ...getState().movements.searchCriteria };

            if (response.entity.length < getState().movements.searchCriteria.pageSize) {
                searchCriteriaToSave.hasNextPage = false;
            } else {
                searchCriteriaToSave.hasNextPage = true;
            }

            dispatch(uiActionCreator.startFetchingPagedList(true));

            dispatch(actionsCreator.setSearchCriteria(searchCriteriaToSave));

            promise.resolve(response);
        });

        return promise.promise();
    },
    count: () => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<number>> => {
        let promise = $.Deferred();


        let requestModel = new AbortableRequest(`/payments/count`, RequestMethods.POST, getState().movements.searchCriteria);

        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
                return;
            }

            dispatch(actionsCreator.setCount(response.entity));

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState) => {
        abortAllRequest();
        dispatch(uiActionCreator.startFetchingPagedList(false));
        dispatch(actionsCreator.resetState());
        dispatch(actionsCreator.setSearchCriteria(getDefaultSearchCriteria(eventId)));
        dispatch(sharedActions.load(false));
        dispatch(sharedActions.count());
    },

    delete: () => (dispatch, getState: () => ApplicationState) => {
        let requestModel = new SaveRequest(`/payments/${getState().movements.itemToDelete}/${getState().events.running.id}`, RequestMethods.DELETE, null);
        dispatch(uiActionCreator.startFetchingPagedList(false));
        ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success) {
                dispatch(actionsMiddleware.reload());
            }
        });
    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let seatchCriteria = { ...getState().movements.searchCriteria };
        seatchCriteria.pageNumber += 1;
        dispatch(actionsCreator.setSearchCriteria(seatchCriteria));
        dispatch(sharedActions.load(false));
    },
    reload: () => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionsCreator.resetList());
        dispatch(sharedActions.load(true));
        dispatch(sharedActions.count());
    },
    onFilterChange: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();

        let searchCriteria = { ...getState().movements.searchCriteria };

        switch (propertyName) {
            case "typology":
                searchCriteria.typology = value;
                break;
            case "date":
                searchCriteria.date = value;
                if (moment(value).isValid()) {
                    searchCriteria.date = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "paymentMethod":
                searchCriteria.paymentMethod = value;
                break;
            case "notes":
                searchCriteria.notes = value;
                break;
            case "orderDirection":
                searchCriteria.orderDirection = value;
                break;
            case "paymentItem":
                searchCriteria.paymentItem = value;
                break;
        }

        if (!searchCriteria.typology)
            searchCriteria.paymentItem = null;

        searchCriteria.pageNumber = 0;
        searchCriteria.hasNextPage = false;
        dispatch(actionsCreator.setSearchCriteria(searchCriteria));

        debounceSearch(dispatch);
    },
    resetFilters: () => (dispatch, getState: () => ApplicationState) => {
        const baseState = getDefaultSearchCriteria(getState().movements.searchCriteria.eventId);
        baseState.pageNumber = 0;
        baseState.hasNextPage = false;
        dispatch(actionsCreator.setSearchCriteria(baseState));
        debounceSearch(dispatch);
    }
}


export const reducer: Reducer<MovementState> = (state: MovementState | undefined, incomingAction: MovementsActions): MovementState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            newState = { ...stateToUpdate };
            break;

        case TypeKeys.APPEND_LIST:
            let list = newState.list.concat(incomingAction.list);
            newState = dotProp.set(newState, 'list', [...list]);
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.RESET_LIST:
            newState = dotProp.set(newState, 'list', []);
            break;
        case TypeKeys.SET_COUNT:
            newState = dotProp.set(newState, 'count', incomingAction.count);
            break;
        case TypeKeys.SET_ITEM_TO_DELETE:
            newState = dotProp.set(newState, 'itemToDelete', incomingAction.id);
            break;

    }

    return newState;
}
